import "./App.css";
import React from "react";

import Form from "./components/form";

function App() {
	return (
		<div className='App'>
			<Form />
		</div>
	);
}

export default App;
